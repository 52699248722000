import { canMemberPassTest, findSharedItem } from '@apps/questions/components/QuestionsList/utils'
import moment from 'moment'

export default {
    computed: {
        itemActionsMenuComputed() {
            if(this.showItemActionsMenu) {
                return this.itemActionsMenu
            } else if(this.showSharedItemActionsMenu) {
                return this.sharedItemActionsMenu
            } else if(this.isTestMode) {
                return this.testModeItemActionsMenu
            } else {
                return null
            }
        },
    },
    methods: {
        testModeItemActionsMenu(item) {
            const actions = []

            if(item.type === 'folder') return actions

            actions.push({
                text: this.$trans('Go to test'),
                icon: 'MOTION',
                color: 'primary',
                disabled: !canMemberPassTest(this.memberUuid, item, this.sharedType, this.sharedUuid),
                on: {
                    click: () => {
                        this.checkUuid = item.uuid
                        this.checkDialog = true
                    }
                }
            })

            return actions
        },
        sharedItemActionsMenu(item) {
            const actions = []
            const sharedItem = findSharedItem(item, this.sharedType, this.sharedUuid)

            const selectedOptionsItem = item => {
                if(!sharedItem) return null

                const formatPeriod = 'YYYY-MM-DD HH:mm'

                if(sharedItem.options && sharedItem.options.begin) {
                    sharedItem.options.begin = moment(sharedItem.options.begin).format(formatPeriod)
                    this.updatingOptionsInput.begin = sharedItem.options.begin
                }

                if(sharedItem.options && sharedItem.options.end) {
                    sharedItem.options.end = moment(sharedItem.options.end).format(formatPeriod)
                    this.updatingOptionsInput.end = sharedItem.options.end
                }

                return sharedItem
            }

            if(item.type !== 'folder') {
                actions.push({
                    subheader: this.$trans('Options')
                })

                actions.push({
                    divider: true
                })

                actions.push({
                    text: this.$trans('To schedule'),
                    icon: 'CALENDAR_CLOCK',
                    color: 'primary',
                    on: {
                        click: () => {
                            this.selectedOptionsItem = selectedOptionsItem(item)
                            this.updatingOptionsType = 'period'
                            this.isOptionsDialog = true
                        }
                    }
                })

                if(this.members.length > 0) {
                    actions.push({
                        text: this.$trans('Students'),
                        icon: 'STUDENT',
                        color: 'success',
                        on: {
                            click: () => {
                                this.selectedOptionsItem = selectedOptionsItem(item)
                                this.updatingOptionsType = 'members'
                                this.isOptionsDialog = true
                            }
                        }
                    })
                }
            }

            if(sharedItem) {
                actions.push({
                    divider: true
                })

                actions.push({
                    text: this.$trans('Exclude'),
                    icon: 'CLOSE',
                    color: 'error',
                    on: {
                        click: () => {
                            this.onDetachSharedDocument(item)
                        }
                    }
                })
            }

            return actions
        },
        itemActionsMenu(item) {
            const actions = []
            const outsideActions = this.itemActions(item)

            if(!this.isMediaViewTrashed) {
                actions.push({
                    text: this.$trans('Rename'),
                    icon: 'EDIT',
                    color: 'success',
                    on: {
                        click: () => {
                            this.selectedItem = item
                            this.dialogTitle = this.$trans('Rename')
                            this.isDialog = true
                        }
                    }
                })

                if(outsideActions.length > 0) {
                    actions.push({
                        divider: true
                    })

                    outsideActions.forEach(o => {
                        actions.push(o)
                    })
                }

                if(item.type !== 'folder') {
                    actions.push({
                        text: this.$trans('Options'),
                        icon: 'SETTINGS',
                        color: 'primary',
                        on: {
                            click: () => {
                                this.selectedOptionsItem = item
                                this.updatingOptionsType = 'mainOptions'
                                this.isOptionsDialog = true
                            }
                        }
                    })

                    actions.push({
                        divider: true
                    })

                    actions.push({
                        text: this.$trans('Check'),
                        icon: 'MOTION',
                        color: 'primary',
                        on: {
                            click: () => {
                                this.checkUuid = item.uuid
                                this.checkDialog = true
                            }
                        }
                    })
                }

                actions.push({
                    divider: true
                })

                actions.push({
                    text: this.$trans('Move'),
                    icon: 'MOVE',
                    color: 'primary',
                    on: {
                        click: () => {
                            this.selectedItem = item
                            this.isExplorer = true
                        }
                    }
                })

                if(item.type !== 'folder') {
                    actions.push({
                        text: this.$trans('Copy'),
                        icon: 'COPY',
                        color: 'primary',
                        on: {
                            click: async () => {
                                await this.onCopyQuestions(item)
                            }
                        }
                    })
                }

                actions.push({
                    divider: true
                })

                actions.push({
                    text: this.$trans('Delete'),
                    icon: 'DELETE',
                    color: 'error',
                    on: {
                        click: async () => {
                            await this.onRemoveQuestions(item)
                        }
                    }
                })
            } else {
                actions.push({
                    text: this.$trans('Restore'),
                    icon: 'RESTORE',
                    color: 'warning',
                    on: {
                        click: async () => {
                            await this.onRestoreQuestions(item)
                        }
                    }
                })

                actions.push({
                    text: this.$trans('Delete'),
                    icon: 'CLOSE',
                    color: 'error',
                    on: {
                        click: async () => {
                            await this.onDeleteQuestions(item)
                        }
                    }
                })
            }

            return actions
        },
    }
}
