export default {
    props: {
        value: {
            type: Array,
            default: null
        },
        title: {
            type: String,
            default: null
        },
        payload: {
            type: Object,
            default: function () {
                return {}
            }
        },
        itemActions: {
            type: Function,
            default: function () {
                return []
            }
        },
        sharedType: {
            type: String,
            default: null
        },
        sharedUuid: {
            type: String,
            default: null
        },
        passedType: {
            type: String,
            default: null
        },
        passedUuid: {
            type: String,
            default: null
        },
        members: {
            type: Array,
            default: function () {
                return []
            }
        },
        showSummary: {
            type: Boolean,
            default: false
        },
        selectable: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: false
        },
        searchable: {
            type: Boolean,
            default: false
        },
        viewable: {
            type: Boolean,
            default: false
        },
        disabledItems: {
            type: Boolean,
            default: false
        },
        hideAuthor: {
            type: Boolean,
            default: false
        },
        hideDate: {
            type: Boolean,
            default: false
        },
        hideDefaultHeader: {
            type: Boolean,
            default: false
        },
        excludeSelect: {
            type: Function,
            default: null
        }
    },
}
