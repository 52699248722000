export default {
    data() {
        return {
            formComponentKey: 0,
            listComponentKey: 0,
            isDialog: false,
            dialogTitle: null,
            dialogIcon: null,
            loading: false,
            isDialogDisabled: false,
            questionsType: null,
            formErrors: {},
            mediaView: null,
            collection: [],
            breadcrumbs: [],
            parent: null,
            selectedItem: null,
            selectedItems: [],
            labelSize: 30,
            isPicker: false,
            isExplorer: false,
            isExplorerActionDisabled: false,
            checkDialog: false,
            checkComponentKey: 0,
            checkUuid: null,
            optionsFormComponentKey: 0,
            isOptionsDialog: false,
            isOptionsDialogDisabled: false,
            selectedOptionsItem: null,
            updatingOptionsType: null,
            updatingOptionsInput: {},
            summaryDialog: false,
            selectedSummaryItem: null,
            results: []
        }
    },
}
