import moment from 'moment'
import {
    cleanUpQuestions,
    copyQuestions,
    createQuestions,
    deleteQuestions,
    detachSharedQuestions,
    getQuestionsCollection,
    removeQuestions,
    restoreQuestions,
    shareQuestions,
    updateQuestions,
    updateSharedQuestions
} from '@apps/questions/api'

import ConfirmationSets from '@/utils/ConfirmationSets'
import { filterTestableQuestions } from '@apps/questions/components/QuestionsList/utils'

export default {
    methods: {
        async fetchQuestions(payload) {
            this.loading = true

            payload = payload || {}

            if(this.isMediaViewTrashed) {
                payload.trashed = true
            }

            if(!Object.keys(payload).includes('parent') && this.parent) {
                payload.parent = this.parent.uuid
            }

            try {
                const response = await getQuestionsCollection({ ...this.payload, ...payload })

                this.parent = response.data.parent
                this.breadcrumbs = response.data.breadcrumbs

                const responseData = response.data.data
                    ? response.data.data
                    : []

                this.$emit('loaded', this.parent, this.collection)

                if(this.isTestMode) {
                    this.collection = filterTestableQuestions(
                        this.passedUuid,
                        responseData,
                        this.sharedType,
                        this.sharedUuid
                    )
                } else {
                    this.collection = responseData
                }
            } finally {
                this.loading = false
            }
        },
        async fetchSharedQuestions() {
            await this.fetchQuestions(this.sharedPayload)
        },
        async onCreateQuestions(data) {
            this.isDialogDisabled = true

            try {
                data.type = this.questionsType

                if(this.parent) {
                    data.parent = this.parent.uuid
                }

                const payload = { ...this.payload, ...data }

                await createQuestions(payload)
                this.isDialog = false
                await this.fetchQuestions()
            } catch (error) {
                const errors = error.response.data.errors
                    ? error.response.data.errors
                    : {}

                this.formErrors = errors
            } finally {
                this.isDialogDisabled = false
            }
        },
        async onUpdateQuestions(data, item) {
            this.isDialogDisabled = true

            try {
                data.uuid = item.uuid

                if(this.parent) {
                    data.parent = this.parent.uuid
                }

                const payload = { ...this.payload, ...data }

                await updateQuestions(payload)

                this.isDialog = false

                await this.fetchQuestions()
            } catch (error) {
                const errors = error.response.data.errors
                    ? error.response.data.errors
                    : {}

                this.formErrors = errors
            } finally {
                this.isDialogDisabled = false
            }
        },
        async onCopyQuestions(item) {
            this.$lprogress.begin()

            try {
                const payload = {
                    ...this.payload,
                    uuid: item.uuid
                }

                await copyQuestions(payload)

                await this.fetchQuestions()
            } finally {
                this.$lprogress.end()
            }
        },
        async onRemoveQuestions(item) {
            const isConfirm = await this.$confirm(ConfirmationSets.deleteSet({
                title: `${ this.$trans('Delete') } ${ item.title }`
            }))

            if(!isConfirm) {
                return
            }

            this.loading = true

            try {
                await removeQuestions({
                    uuid: item.uuid,
                    ...this.payload
                })
                await this.fetchQuestions()
            } catch (error) {
            } finally {
                this.loading = false
            }
        },
        async onRestoreQuestions(item) {
            this.loading = true

            try {
                await restoreQuestions({
                    uuid: item.uuid,
                    ...this.payload
                })
                await this.fetchQuestions()
            } catch (error) {
            } finally {
                this.loading = false
            }
        },
        async onDeleteQuestions(item) {
            const isConfirm = await this.$confirm(ConfirmationSets.deleteAndCannotUndoneSet({
                title: `${ this.$trans('Delete') } ${ item.title }`
            }))

            if(!isConfirm) {
                return
            }

            this.loading = true

            try {
                await deleteQuestions({
                    uuid: item.uuid,
                    ...this.payload
                })
                await this.fetchQuestions()
            } catch (error) {
            } finally {
                this.loading = false
            }
        },
        async onCleanUpQuestions() {
            const isConfirm = await this.$confirm(ConfirmationSets.deleteAndCannotUndoneSet({
                title: `${ this.$trans('Clean up trash') }`
            }))

            if(!isConfirm) {
                return
            }

            this.loading = true

            try {
                await cleanUpQuestions(this.payload)
                await this.fetchQuestions()
            } catch (error) {
            } finally {
                this.loading = false
            }
        },
        async onMoveItem(folder) {
            this.$lprogress.begin()

            try {
                const payload = {
                    uuid: this.selectedItem.uuid,
                    parent: folder ? folder.uuid : null
                }

                await updateQuestions(payload)

                await this.fetchQuestions()
            } finally {
                this.$lprogress.end()
            }
        },
        async onShareDocuments(items) {
            const data = []

            items.forEach(questions_uuid => {
                data.push({
                    shared_uuid: this.sharedUuid,
                    shared_type: this.sharedType,
                    questions_uuid
                })
            })

            try {
                await shareQuestions(data)
                await this.fetchSharedQuestions()
            } finally {
                this.$lprogress.end()
            }
        },
        async onDetachSharedDocument(item) {
            this.loading = true

            try {
                await detachSharedQuestions({
                    questions_uuid: item.uuid,
                    shared_type: this.sharedType,
                    shared_uuid: this.sharedUuid
                })

                this.fetchSharedQuestions()
            } finally {
                this.loading = false
            }
        },
        async onUpdateMainOptions(options) {
            this.isOptionsDialogDisabled = true

            try {
                const payload = {
                    options,
                    uuid: this.selectedOptionsItem.uuid
                }
                await updateQuestions(payload)

                this.isOptionsDialog = false

                await this.fetchQuestions()
            } catch (error) {
                // ...
            } finally {
                this.isOptionsDialogDisabled = false
            }
        },
        async onUpdateSharedItemOptions(options, item) {
            if(options.begin) {
                options.begin = moment(options.begin).valueOf()
            }

            if(options.end) {
                options.end = moment(options.end).valueOf()
            }

            this.isOptionsDialogDisabled = true

            try {
                const uuid = this.selectedOptionsItem.uuid
                await updateSharedQuestions({options, uuid})
                this.isOptionsDialog = false

                await this.fetchSharedQuestions()
            } catch (error) {
                this.formErrors = error.response.data.errors
                    ? error.response.data.errors
                    : {}
            } finally {
                this.isOptionsDialogDisabled = false
            }
        }
    }
}
