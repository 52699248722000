import { required } from '@/utils/validation'

export default {
    computed: {
        headers() {
            const headers = []

            headers.push({
                text: this.$trans('Title'),
                value: 'title',
            })

            if(!this.isMobile && this.showSummary) {
                headers.push({
                    text: this.$trans('Duration'),
                    value: 'duration',
                    sortable: false,
                    width: 200,
                })

                headers.push({
                    text: this.$trans('Questions count'),
                    value: 'sets_count',
                    width: 200,
                })

                headers.push({
                    text: this.$trans('Estimate'),
                    value: 'sets_sum_score',
                    width: 200,
                })
            }

            if(!this.isMobile && !this.hideAuthor) {
                headers.push({
                    text: this.$trans('Author'),
                    value: 'author',
                    width: 160,
                    sortable: false
                })
            }

            if(!this.isMobile && !this.hideDate) {
                headers.push({
                    text: this.$trans('Updated at'),
                    value: 'updated_at',
                    width: 100,
                    itemClass: 'px-0',
                    class: 'px-0'
                })
            }

            if(this.showItemActionsMenu ||
                this.showSharedItemActionsMenu ||
                this.isTestMode
            ) {
                const headActions = {
                    value: 'actions',
                    width: 48,
                    itemClass: 'px-0',
                    class: 'px-0',
                    meta: {
                        menuBindings: {}
                    }
                }

                headers.push(headActions)
            }

            return headers
        },
        listWrapperBindings() {
            const bindings = {
                class: [ 'pa-0' ]
            }

            if(this.height) {
                bindings.style = {
                    height: this.height + 'px'
                }
            }

            return bindings
        },
        isFooter() {
            return !!this.$slots.footer
        },
        formFields() {
            return [
                {
                    type: 'v-text-field',
                    name: 'title',
                    props: {
                        label: this.$trans('Title'),
                        dense: true,
                        rules: [
                            required
                        ]
                    }
                }
            ]
        },
        sharedPayload() {
            return {
                shared_type: this.sharedType,
                shared_uuid: this.sharedUuid,
                passed_type: this.passedType,
                passed_uuid: this.passedUuid
            }
        },
        pickerPayload() {
            return {
                author: this.memberUuid
            }
        },
        memberUuid() {
            return this.$auth.user.uuid
        },
        isMediaViewShared() {
            return !!this.sharedType &&
                !!this.sharedUuid
        },
        isTestMode() {
            return !!this.passedType &&
                !!this.passedUuid &&
                !!this.sharedType &&
                !!this.sharedUuid
        },
        isMediaViewSearch() {
            return this.mediaView === 'search'
        },
        isMediaViewTrashed() {
            return this.mediaView === 'trashed'
        },
        showActionsMenu() {
            return this.actionsMenu.length > 0 &&
                !this.isMediaViewSearch
        },
        showItemActionsMenu() {
            return this.editable &&
                !this.isMediaViewShared
        },
        showSharedItemActionsMenu() {
            return this.editable &&
                this.isMediaViewShared
        },
        showDividerBeforeCommonMenu() {
            return !this.isMobile &&
                this.commonMenu.length > 0 &&
                !this.isMediaViewSearch
        },
        showCommonMenu() {
            return this.commonMenu.length > 0 && !this.isMediaViewSearch
        },
        currentFolderUuid() {
            if(!this.parent) {
                return null
            }

            return this.parent.uuid
        },
        actionsMenu() {
            if(!this.editable) {
                return []
            }

            const items = []

            if(this.isMediaViewTrashed) {
                items.push({
                    text: this.$trans('Clean up trash'),
                    icon: 'CLEAN_UP',
                    color: 'error',
                    disabled: this.collection.length === 0,
                    on: {
                        click: () => {
                            this.onCleanUpQuestions()
                        }
                    }
                })
            }

            if(this.editable && this.isMediaViewShared) {
                items.push({
                    text: this.$trans('Add test'),
                    icon: 'ADD',
                    color: 'primary',
                    on: {
                        click: () => {
                            this.isPicker = true
                        }
                    }
                })
            }

            if(this.editable &&
                !this.isMediaViewShared &&
                !this.isMediaViewSearch &&
                !this.isMediaViewTrashed
            ) {
                items.push({
                    text: this.$trans('Create folder'),
                    icon: 'FOLDER_PLUS',
                    color: 'primary',
                    on: {
                        click: () => {
                            this.selectedItem = null
                            this.formErrors = {}
                            this.questionsType = 'folder'
                            this.dialogTitle = this.$trans('Create folder')
                            this.dialogIcon = 'FOLDER'
                            this.isDialog = true
                        }
                    }
                })

                items.push({
                    text: this.$trans('Create test'),
                    icon: 'ADD',
                    color: 'primary',
                    on: {
                        click: () => {
                            this.selectedItem = null
                            this.formErrors = {}
                            this.questionsType = 'questions'
                            this.dialogTitle = this.$trans('Create test')
                            this.dialogIcon = 'QUESTIONS'
                            this.isDialog = true
                        }
                    }
                })
            }

            return items
        },
        commonMenu() {
            if(this.isMediaViewTrashed) {
                return []
            }

            const items = []

            if(this.editable && !this.isMediaViewShared) {
                items.push({
                    text: this.$trans('Trash'),
                    icon: 'TRASH',
                    color: 'error',
                    on: {
                        click: () => {
                            this.toMediaView('trashed')
                            this.fetchQuestions({ trashed: true })
                        }
                    }
                })
            }

            if(this.isTestMode) {
                items.push({
                    text: this.$trans('Update'),
                    icon: 'REFRESH',
                    color: 'warning',
                    on: {
                        click: async () => {
                            await this.fetchSharedQuestions()
                        }
                    }
                })
            }

            return items
        }
    }
}
