import { integerValidator, isDateAfter, isDateBefore, isDateInFuture, requiredIf } from '@/utils/validation'
import moment from 'moment'
import { getQuestionsTitle } from '@apps/questions/components/QuestionsList/utils'

export default {
    computed: {
        optionsForm() {
            if(!this.selectedOptionsItem) {
                return {
                    title: this.$trans('Options')
                }
            }

            const optionsType = this.updatingOptionsType
            let formBindings = null

            switch (optionsType) {
                case 'mainOptions':
                    formBindings = {
                        title: this.selectedOptionsItem.title,
                        fields: [
                            {
                                type: 'v-text-field',
                                name: 'duration',
                                props: {
                                    outlined: true,
                                    dense: true,
                                    label: this.$trans('Duration'),
                                    hint: this.$trans('questions.hints.duration_set'),
                                    persistentHint: true,
                                    prependInnerIcon: 'mdi-clock-fast',
                                    suffix: this.$trans('minutes'),
                                    rules: [
                                        integerValidator
                                    ]
                                },
                                filter: value => {
                                    const check = [ '', 0, '0', null, 'null', 'undefined', undefined, false, true ]

                                    if(check.includes(value)) return null

                                    return Number(value)
                                }
                            },
                            {
                                type: 'v-checkbox',
                                name: 'skip_question',
                                props: {
                                    dense: true,
                                    label: this.$trans('questions.hints.skip_question_set'),
                                    messages: this.$trans('questions.hints.skip_question_set_hint')
                                }
                            }
                        ],
                        on: {
                            update: this.onUpdateMainOptions
                        }
                    }

                    break
                case 'period':
                    formBindings = {
                        title: getQuestionsTitle(this.selectedOptionsItem.questions_uuid, this.collection),
                        label: `${ this.$trans('To schedule') } ${ this.$trans('Test') }`,
                        hint: this.$trans('questions.hints.shared_pass_period'),
                        fields: [
                            {
                                type: 'v-datetime-picker',
                                name: 'begin',
                                props: {
                                    label: this.$trans('Begin'),
                                    clearText: this.$trans('Clean up'),
                                    okText: this.$trans('Set up'),
                                    textFieldProps: {
                                        outlined: true,
                                        dense: true,
                                        rules: [
                                            isDateInFuture,
                                            v => requiredIf(v, this.updatingOptionsInput.end, { other: this.$trans('End') }),
                                            v => isDateBefore(v, this.updatingOptionsInput.end, { date: this.$trans('End') })
                                        ]
                                    },
                                    datePickerProps: {
                                        firstDayOfWeek: 1,
                                        locale: this.$store.getters.locale,
                                        flat: true
                                    },
                                    timePickerProps: {
                                        format: '24hr'
                                    }
                                }
                            },
                            {
                                type: 'v-datetime-picker',
                                name: 'end',
                                props: {
                                    label: this.$trans('End'),
                                    clearText: this.$trans('Clean up'),
                                    okText: this.$trans('Set up'),
                                    textFieldProps: {
                                        outlined: true,
                                        dense: true,
                                        rules: [
                                            isDateInFuture,
                                            v => requiredIf(v, this.updatingOptionsInput.begin, { other: this.$trans('Begin') }),
                                            v => isDateAfter(v, this.updatingOptionsInput.begin, { date: this.$trans('Begin') })
                                        ]
                                    },
                                    datePickerProps: {
                                        firstDayOfWeek: 1,
                                        locale: this.$store.getters.locale,
                                        flat: true
                                    },
                                    timePickerProps: {
                                        format: '24hr'
                                    }
                                }
                            }
                        ],
                        on: {
                            input: this.onOptionsInput,
                            update: this.onUpdateSharedItemOptions
                        }
                    }

                    break
                case 'members':
                    formBindings = {
                        title: getQuestionsTitle(this.selectedOptionsItem.questions_uuid, this.collection),
                        label: this.$trans('Students'),
                        hint: this.$trans('questions.hints.shared_pass_members'),
                        fields: [
                            {
                                type: 'v-select',
                                name: 'members',
                                props: {
                                    multiple: true,
                                    chips: true,
                                    clearable: true,
                                    items: this.members
                                }
                            }
                        ],
                        on: {
                            input: this.onOptionsInput,
                            update: this.onUpdateSharedItemOptions
                        }
                    }

                    break
                default:
                    formBindings = {
                        title: 'Form Undefined',
                        fields: [],
                        on: {}
                    }
            }

            return formBindings
        }
    },
    methods: {
        onOptionsInput(field, value, formData, resetForm) {
            const format = 'YYYY-MM-DD HH:mm'
            let begin = null
            let end = null

            if(formData.begin) begin = moment(formData.begin).format(format)
            if(formData.end) end = moment(formData.end).format(format)

            resetForm()

            this.updatingOptionsInput.begin = begin
            this.updatingOptionsInput.end = end
        }
    }
}
