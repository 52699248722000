<template>
    <v-card
        tile
        flat
        :loading="loading"
        :disabled="loading"
        class="media-table"
    >
        <div class="d-flex align-center px-2 py-2">
            <span v-if="title" class="subtitle-1 font-weight-bold mx-2">{{ title }}</span>
            <questions-list-breadcrumbs
                :is-media-view-search="isMediaViewSearch"
                :is-media-view-trashed="isMediaViewTrashed"
                :shared-uuid="sharedUuid"
                :parent="parent"
                :items="breadcrumbs"
                class="text-truncate"
                @click="fetchQuestions"
                @comback="onComback"
            />
            <questions-list-search-form
                v-if="isMediaViewSearch"
                @input="fetchQuestions"
                @clear="clearUpData"
                class="flex-fill pl-4"
            />
            <v-spacer/>
            <v-btn
                v-if="!isMediaViewSearch && !isMediaViewTrashed && searchable"
                icon
                @click="toMediaView('search')"
            >
                <w-icon
                    value="SEARCH"
                />
            </v-btn>
            <v-divider
                v-if="!isMobile && !isMediaViewSearch && searchable && showActionsMenu"
                vertical
                class="mx-2"
            />
            <slot name="actions">
                <w-actions-menu
                    v-if="showActionsMenu"
                    icon="ADD"
                    :activator="{ color: 'primary' }"
                    :inline="!isMobile"
                    :inline-icon="actionsMenu.length > 1"
                    :items="actionsMenu"
                />
            </slot>
            <v-divider
                v-if="showDividerBeforeCommonMenu && showActionsMenu"
                vertical
                class="ml-2 mr-1"
            />
            <w-actions-menu
                v-if="showCommonMenu"
                :inline="!isMobile"
                :inline-icon="commonMenu.length > 1"
                :items="commonMenu"
            />
        </div>
        <v-divider v-if="hideDefaultHeader"/>
        <v-card-text v-bind="listWrapperBindings">
            <slot
                name="body"
                :listKey="`listcomponentkey-${listComponentKey}`"
                :items="collection"
                :itemActionsMenu="itemActionsMenu"
                :selectable="selectable"
                :editable="editable"
                :hideAuthor="hideAuthor"
                :hideDate="hideDate"
                :disabledItems="disabledItems"
                :isMediaViewShared="isMediaViewShared"
                :onClickItem="onClickItem"
                :onCheckedItem="onCheckedItem"
                :onUncheckedItem="onUncheckedItem"
                :onDetachSharedDocument="onDetachSharedDocument"
                :onQuestionsProcess="onQuestionsProcess"
            >
                <w-fx-table
                    v-model="selectedItems"
                    :key="`listcomponentkey-${listComponentKey}`"
                    :hide-default-header="hideDefaultHeader"
                    :headers="headers"
                    :items="collection"
                    item-key="uuid"
                    :selectable="selectable"
                    :exclude-select="excludeSelect"
                    @input="$emit('input', selectedItems)"
                    @click:row="item => onClickItem(item)"
                >
                    <template #item.title="{item}">
                        <questions-title
                            :item="item"
                            :show-summary="showSummary && isMobile"
                            :shared-type="sharedType"
                            :shared-uuid="sharedUuid"
                            class="py-3"
                        />
                    </template>
                    <template #item.duration="{item}">
                        {{ item.options.duration | questDurationLabel }}
                    </template>
                    <template #item.sets_count="{item}">
                        {{ item.sets_count | questSetsLabel }}
                    </template>
                    <template #item.sets_sum_score="{item}">
                        {{ item.sets_sum_score | questScoresLabel }}
                    </template>
                    <template #item.author="{item}">
                        {{ item.author | memberFullName('short') }}
                    </template>
                    <template #item.updated_at="{item}">
                        {{ item.updated_at | dateFormat('DD.MM.YYYY') }}
                    </template>
                    <template #item.actions="{head, item}">
                        <w-icon
                            v-if="isTestMode"
                            value="NEXT"
                            color="primary"
                        />
                        <w-actions-menu
                            v-else
                            hide-if-empty
                            :items="itemActionsMenuComputed(item)"
                            v-bind="head.meta.menuBindings"
                        />
                    </template>
                </w-fx-table>
            </slot>
        </v-card-text>
        <v-divider v-if="isFooter"/>
        <div v-if="$slots.footer" class="px-4 py-3">
            <slot name="footer"></slot>
        </div>
        <w-dialog-drawer
            v-model="isDialog"
            :title="dialogTitle"
            :icon="dialogIcon"
            :disabled="isDialogDisabled"
        >
            <div class="pa-4">
                <w-simple-form
                    :key="`formcomponent${formComponentKey}`"
                    :fields="formFields"
                    :errors="formErrors"
                    :item="selectedItem"
                    @save="onCreateQuestions"
                    @update="onUpdateQuestions"
                />
            </div>
        </w-dialog-drawer>
        <questions-picker
            v-if="isMediaViewShared"
            v-model="isPicker"
            :payload="pickerPayload"
            @select="onShareDocuments"
        />
        <questions-explorer
            v-if="editable && !isMediaViewShared"
            v-model="isExplorer"
            :title="$trans('Move to')"
            :action-text="$trans('Move here')"
            :disabled-action="isExplorerActionDisabled"
            @loaded="onLoadedExplorer"
            @select="onMoveItem"
        />
        <w-aside-drawer
            v-model="isOptionsDialog"
            :title="optionsForm.title"
            :disabled="isOptionsDialogDisabled"
        >
            <div class="pa-4">
                <w-simple-form
                    :key="`optionsFormComponentKey-${optionsFormComponentKey}`"
                    v-if="selectedOptionsItem"
                    :fields="optionsForm.fields"
                    :item="selectedOptionsItem.options"
                    :errors="formErrors"
                    v-on="optionsForm.on"
                >
                    <template #field.begin="{attrs, on, data}">
                        <label v-if="optionsForm.label">{{ optionsForm.label }}</label>
                        <div
                            v-if="optionsForm.hint"
                            class="caption mb-3"
                        >
                            {{ optionsForm.hint }}
                        </div>
                        <v-datetime-picker
                            v-model="data.begin"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <template #dateIcon>
                                <v-icon>mdi-calendar</v-icon>
                            </template>
                            <template #timeIcon>
                                <v-icon>mdi-clock</v-icon>
                            </template>
                        </v-datetime-picker>
                    </template>

                    <template #field.end="{attrs, on, data}">
                        <v-datetime-picker
                            v-model="data.end"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <template #dateIcon>
                                <v-icon>mdi-calendar</v-icon>
                            </template>
                            <template #timeIcon>
                                <v-icon>mdi-clock</v-icon>
                            </template>
                        </v-datetime-picker>
                    </template>

                    <template #field.members="{attrs, on, data}">
                        <label v-if="optionsForm.label">{{ optionsForm.label }}</label>
                        <div
                            v-if="optionsForm.hint"
                            class="caption mb-3"
                        >
                            {{ optionsForm.hint }}
                        </div>
                        <v-select
                            v-model="data.members"
                            v-bind="attrs"
                            v-on="on"
                        />
                    </template>
                </w-simple-form>
            </div>
        </w-aside-drawer>
        <v-dialog
            v-model="checkDialog"
            scrollable
            persistent
            max-width="800"
        >
            <questions-process
                :key="`questions-process${checkComponentKey}`"
                :uuid="checkUuid"
                scrollable
                height="100vh"
                :shared-type="sharedType"
                :shared-uuid="sharedUuid"
                :passed-type="passedType"
                :passed-uuid="passedUuid"
                @close="checkDialog = false"
                @finish="onQuestionsFinish"
            />
        </v-dialog>
        <v-dialog
            v-model="summaryDialog"
            max-width="500"
        >
            <v-card
                v-if="selectedSummaryItem"
            >
                <v-card-title>{{ selectedSummaryItem.title }}</v-card-title>
                <v-card-subtitle>
                    <strong>{{ $trans('Author') }}</strong>:&nbsp;
                    <span>{{ selectedSummaryItem.author | memberFullName }}</span>
                </v-card-subtitle>
                <v-divider/>
                <v-card-text class="pt-5">
                    <questions-list-shared-period
                        :shared-uuid="sharedUuid"
                        :shared-type="sharedType"
                        :item="selectedSummaryItem"
                        :label="`${$trans('Scheduled for')}:`"
                        show-begin
                    />
                </v-card-text>
                <v-divider/>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        text
                        color="primary"
                        @click="summaryDialog = false"
                    >
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import mobileBreakpointChecker from '@/mixins/mobileBreakpointChecker'
import _ from 'lodash'
import hasMemberFullNameFilterMixin from '@/components/Member/mixins/hasMemberFullNameFilterMixin'
import questionsListHeadersMixin from './mixins/questionsListComputedMixin'
import questionsListOptionsFormMixin from './mixins/questionsListOptionsFormMixin'
import questionsListItemActionsMenuMixin from './mixins/questionsListItemActionsMenuMixin'
import questionsListDataPropsMixin from './mixins/questionsListDataPropsMixin'
import questionsListApiMixin from './mixins/questionsListApiMixin'
import questionsListPropsMixin from './mixins/questionsListPropsMixin'
import { canMemberPassTest } from '@apps/questions/components/QuestionsList/utils'
import questionsFiltersMixin from '@apps/questions/mixins/questionsFiltersMixin'

export default {
    name: 'QuestionsList',
    components: {
        QuestionsListSharedPeriod: () => import('@apps/questions/components/QuestionsList/QuestionsListSharedPeriod'),
        QuestionsTitle: () => import('@apps/questions/components/QuestionsTitle'),
        QuestionsProcess: () => import('@apps/questions/components/QuestionsProcess'),
        QuestionsPicker: () => import('@apps/questions/components/QuestionsPicker'),
        QuestionsListSearchForm: () => import('@apps/questions/components/QuestionsList/QuestionsListSearchForm'),
        QuestionsListBreadcrumbs: () => import('@apps/questions/components/QuestionsList/QuestionsListBreadcrumbs'),
        QuestionsExplorer: () => import('@apps/questions/components/QuestionsExplorer')
    },
    mixins: [
        mobileBreakpointChecker,
        hasMemberFullNameFilterMixin,
        questionsFiltersMixin,
        questionsListPropsMixin,
        questionsListDataPropsMixin,
        questionsListHeadersMixin,
        questionsListOptionsFormMixin,
        questionsListItemActionsMenuMixin,
        questionsListApiMixin
    ],
    watch: {
        isMediaViewSearch(flag) {
            if(flag) {
                this.clearUpData()
            }
        },
        collection() {
            this.resetList()
        },
        value(selectedItems) {
            if(selectedItems.length === 0) {
                this.resetList()
            }
        },
        isDialog(flag) {
            if(flag) {
                this.formComponentKey++
            }
        },
        checkDialog(flag) {
            if(flag) {
                this.checkComponentKey++
            }
        },
        isOptionsDialog(flag) {
            if(!flag) {
                this.selectedOptionsItem = null
                this.formErrors = {}
                this.updatingOptionsInput = {}
            }
        },
        summaryDialog(flag) {
            if(!flag) {
                this.selectedSummaryItem = null
            }
        }
    },
    mounted() {
        if(!this.isMediaViewSearch) {
            if(this.isMediaViewShared) {
                this.fetchSharedQuestions()
            } else {
                this.fetchQuestions()
            }
        } else {
            this.clearUpData()
        }
    },
    methods: {
        clearUpData() {
            this.collection = []
            this.breadcrumbs = []
            this.parent = null
        },
        resetList() {
            if(this.selectedItems.length > 0) {
                this.listComponentKey++
                this.selectedItems = []
            }

            this.$emit('input', this.selectedItems)
        },
        toMediaView(view) {
            this.mediaView = view
        },
        onClickItem(item) {
            if(item.type === 'folder') {
                this.fetchQuestions({ parent: item.uuid })
            } else if(this.isTestMode) {
                if(canMemberPassTest(this.memberUuid, item, this.sharedType, this.sharedUuid)) {
                    this.checkUuid = item.uuid
                    this.checkDialog = true
                } else {
                    this.selectedSummaryItem = item
                    this.summaryDialog = true
                }
            } else {
                this.$emit('click:item', item, this.parent)
            }
        },
        onComback() {
            this.mediaView = null
            this.parent = null

            if(this.isMediaViewShared) {
                this.fetchSharedQuestions()
            } else {
                this.fetchQuestions()
            }
        },
        onCheckedItem(item) {
            this.selectedItems.push(item.uuid)
            this.$emit('input', this.selectedItems)
        },
        onUncheckedItem(item) {
            this.selectedItems = this.selectedItems.filter(o => o !== item.uuid)
            this.$emit('input', this.selectedItems)
        },
        onLoadedExplorer(folder) {
            const dist = _.get(folder, 'uuid', null)
            const target = _.get(this.selectedItem, 'parent_uuid')
            const itemUuid = _.get(this.selectedItem, 'uuid')

            this.isExplorerActionDisabled = dist === target || dist === itemUuid
        },
        onQuestionsProcess(item) {
            this.checkUuid = item.uuid
            this.checkDialog = true
        },
        onQuestionsFinish() {
            this.checkDialog = false
            this.fetchSharedQuestions()
        }
    }
}
</script>
