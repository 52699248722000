import moment from 'moment'
import Debugger from '@/utils/Debugger'

export const findSharedItem = (item, sharedType, sharedUuid) => {
    if(!item.shared) {
        // console.warn('questions.shared not defined')

        return null
    }

    if(!Array.isArray(item.shared)) {
        Debugger.warn('questions.shared must be an array')

        return null
    }

    return item.shared.find(o => {
        return o.shared_type === sharedType &&
            o.shared_uuid === sharedUuid &&
            o.questions_uuid === item.uuid
    })
}

export const getQuestionsTitle = (uuid, collection) => {
    const questions = collection.find(o => o.uuid === uuid)

    if(!questions) return 'Unknown title'

    return questions.title
}

export const canMemberPassTest = (memberUuid, item, sharedType, sharedUuid) => {
    const sharedItem = findSharedItem(item, sharedType, sharedUuid)

    if(!sharedItem) return true
    if(!sharedItem.options) return true

    const members = sharedItem.options.members
    const begin = sharedItem.options.begin
    const end = sharedItem.options.end

    let isDateAvailable = true
    let isUserAvailable = true

    if(begin && end) {
        isDateAvailable = moment().isBetween(begin, end)
    }

    if(members && Array.isArray(members) && members.length > 0) {
        isUserAvailable = members.includes(memberUuid)
    }

    return isDateAvailable && isUserAvailable
}

/**
 * Filter items
 * @param memberUuid
 * @param item
 * @param sharedType
 * @param sharedUuid
 * @returns {boolean|*}
 */
export const showTestableItem = (memberUuid, item, sharedType, sharedUuid) => {
    const sharedItem = findSharedItem(item, sharedType, sharedUuid)

    const hasResults = Number(item.results_count) > 0

    let hasMember = false
    if(hasSharedItemMembers(sharedItem)) {
        hasMember = sharedItem.options.members.includes(memberUuid)
    }

    let hasPeriod = true
    if(hasSharedItemPeriod(sharedItem)) {
        hasPeriod = moment(sharedItem.options.end).isAfter(moment()) ||
            moment().isBetween(sharedItem.options.begin, sharedItem.options.end)
    }

    if(!hasPeriod) return false

    if(hasResults && hasMember) return true

    if(hasResults && !hasMember) return false

    return true
}

export const hasSharedItemMembers = item => {
    if(!item) return false
    if(!item.options) return false

    const members = item.options.members

    return members && Array.isArray(members) && members.length > 0
}

export const hasSharedItemPeriod = item => {
    if(!item) return false
    if(!item.options) return false

    return !!item.options.begin && !!item.options.end
}

export const filterTestableQuestions = (memberUuid, collection, sharedType, sharedUuid) => {
    const collectionComputed = []

    collection.forEach((item) => {
        const isShowItem = showTestableItem(
            memberUuid,
            item,
            sharedType,
            sharedUuid
        )

        if(isShowItem) {
            collectionComputed.push(item)
        }
    })

    return collectionComputed
}
